@import '../design-system';

.heatmap-container {
    width: 340px;
    position: relative;
}

.graph {
    position: absolute;
    top: 23px;
    left: 114px;
}

.angle-text {
    position: absolute;
    @include text-caption1($text-light);
}

.graph-menu {
    position: absolute;
    right: -20px;
    top: -20px;
}

.segment-input {
    position: absolute;
    left: 0px;
    top: 185px;
    width: 100px;
}

.offset-input {
    position: absolute;
    right: 0px;
    top: 185px;
    width: 100px;
}

.tool-hr {
    position: absolute;
    top: 150px;
    left: 0px;
    width: 100%;
}

.info-button {
    position: absolute;
    top: -5px;
    left: -5px;
    cursor: pointer;
}
