@import '../design-system';

$min-column-width: 300px;
$max-column-width: 500px;

.discussion-graph-header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
}

.page-container {
    overflow: auto;
    position: absolute;
    margin-top: 60px;
    height: calc(100% - 60px);
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 40px auto;
    grid-template-areas:
    "header"
    "graph";
}

.header-container {
    grid-area: header;
    display: flex;
    flex-wrap: nowrap;

    .header-spacer {
        width: 85px;
        height: 40px;
    }

    .header-text {
        line-height: 40px;
        text-align:center;
        overflow: hidden;
        min-width: $min-column-width;
        max-width: $max-column-width;
        flex-grow: 1;
        background-color: $text-blue;
        border-radius: 5px;
        margin-right: 10px;
        color: white;
        cursor: pointer;
        @include noselect;
    }
}

.graph-container {
    display: flex;
    grid-area: graph;
    overflow-y: overlay;
    overflow-x: hidden;
    height: calc(100% - 20px);

    .timeline {
        position: relative;
        width: 70px;
        min-width: 70px;
        margin-left:15px;
        font-size: 11px;
        line-height: 0px;
        color:$text-blue;

        .timestamp {
            position: relative;
            margin-top: 10px;
            margin-bottom: 100px;
        }
    }

    .transcript-column {
        position: relative;
        min-width: $min-column-width;
        max-width: $max-column-width;
        flex-grow: 1;

        .transcript {
            position: absolute;
            width: calc(100% - 15px);
            overflow-y: auto;
            overflow-x: hidden;
            padding-left: 5px;
            min-height: 40px;
            font-size: 16px;
            background-color: #ffffff;
            box-shadow: 3px 3px 5px #b5b5b5;
            border-radius: 5px;
            color:#414142;

            .transcript-text {
                margin: 5px 7px 5px 7px;
                line-height: 30px;

                .keyword-text {
                    cursor: pointer;
                }

                .speaker-tag {
                    font-weight: bold;
                }

                .question-highlight {
                    background-color: yellow;
                }

                .question-mark {
                    font-size:18px;
                    height: 25px;
                    min-width: 15px;
                    color: white;
                    font-style: bold;
                    position: absolute;
                    border-radius: 0px 5px 0px 5px;
                    top: 0;
                    right: 0;
                    background-color: $solid-blue;
                    height: 29px;
                    text-align: center;
                    cursor: pointer;
                    &:hover{
                        background-color: yellow;
                        color: black;
                    }
                }
            }
        }
    }
}

.graph-box {
    position: relative;
    display: flex;
    justify-content: center;
    height: 200px;
    width: 100%;

    .pie-chart {
        position: absolute;
        height: 200px;
    }

    .pie-piece-text {
        position: absolute;
        height: 40px;
        width: 48px;
        background-color: #ffffff;
        text-align: center;
        line-height: 40px;
        border-radius: 40px;
        top: 80px;
    }
}

.graph-legend {
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
    margin-bottom: 15px;
    width: 240px;
    align-content: center;

    .color-box {
        height: 15px;
        width: 15px;
        margin-left: 5px;
        margin-top: 4px;
    }

    .name {
        padding-right: 10px;
        width: 80px;
        height: 20px;
        padding-left: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.question-container {
    max-height: 250px;
    min-height: 0px;
    position:relative;
    overflow: auto;

    .question-item {
        background-color: #f7f8fc;
        box-shadow: 5px 5px 10px #888888; // 2px 2px 5px #888888;
        border-radius: 5px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        color:#414142;
        font-size: 16px;
        margin: 10px 5px 10px 10px;
        width: 85%;
        padding: 10px;
    }
}
