@import '../design-system';
$highlight-keywords: rgba(47%, 47%, 98%, 1);

.timestamp {
	display: inline-block;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: #B9B9BD;
	padding-right: 10px;
	padding-left: 10px;
	text-align: center;
	height: 21px;
}

.transcript-container {
	width: 100%;
	position: relative;
	padding-top: 16px;
	padding-bottom: 16px;
}

.transcript-text {
	display: inline-block;
	width: calc(100% - 100px);
	margin-left: 90px;
	margin-right: 10px;
}

.highlight-keyword {
	font-weight: bold;
  cursor: pointer;
}

.transcript-list {
  width: 100%;
	height: 100%;
	overflow-y: auto;
}

.highlighted {
	border-color: $solid-blue;
	border-width: 25px;
	border-style: none none none solid;
	box-sizing: border-box;
}

.dialog-box {
  width: 240px;
}

hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0px;
}

.bold {
  font-weight: bold;
}
