@import '../design-system';

.overview-container {
  width: 400px;
  height: 100%;
  overflow: auto;
}

.time-button-container {
  text-align: center;
}

.dialog-heading {
  text-align: center;
  align-items: center;
  @include text-headline;
}

.dialog-body {
  text-align: left !important;
  max-height: 300px;
  overflow-y: auto;
}

.dialog-button-container {
  width: 100%;
  margin: $space-S 0;
}

.dialog-button {
  display: inline-block;
  width: 33%;
  border-radius: 26px;
  border: none;
  background: transparent;
  box-sizing: border-box;
  padding: 12px 8px 12px 8px;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  @include text-subhead($text-light-blue);
  &:focus {
    outline: 0;
  }
}

.dialog-content {
  min-width: 250px;
}

.dialog-blur {
  filter: blur(2px);
}

.left-button {
    float: left;
}

.right-button {
    float: right;
}

.slider-input {
  width: 300px;
}

.timeline{
  position: relative;
  margin: 20px 40px;
}

.timeline-handle {
	position: absolute;
	width: 20px;
  height: 50px;
  background-color: rgba(100, 100, 100, 0.5);
  cursor: pointer;
}