// Global color definitions

// Text
$text-black: #2B2B2E;
$text-error: #DC2828;
$text-blue: #6466E3;
$text-regular: #58585C;
$text-light: #727278;
$text-white: #FAFAFC;
$text-light-blue: #3991CC;
$text-red: #DC2929;

// Solid
$solid-background: #F7F8FC;
$solid-white: #FCFDFF;
$solid-ultra-white: #FFFFFF;
$solid-blue: #7878FA;
$solid-red: #FF6363;
$solid-green: #6BDA88;
$solid-light-grey: #CACACE;
$solid-lighter-grey: #E5E5E6;

// Gradient
$gradient-blue: linear-gradient(147.59deg, #764BFF 0%, #7BB7F1 100%);

// Shadow
$shadow-blue: #454599;
$shadow-blue-alpha: rgba(69, 69, 153, 0.2);
$button-shadow: rgba(12%, 7%, 60%, 0.3);
