@import '../design-system';
$questionColor: #FAB978;

.timeline-container {
    height: 104px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
}

.legend {
    display: flex;
    min-height: 28px;
    max-height: 28px;
    width: 100%;
    //box-shadow: 0px 1px rgba(0,0,0,0.08);
    align-items: flex-start;
    overflow-y: hidden;
    white-space: nowrap;
}

.line {
    background-color: #E3E5F1;
    min-height: 1px;
    width: 100%;
}

.color-box {
    display: flex;
    width: 10px;
    height: 10px;
    margin-right: $space-S;
    margin-top: $space-XS;
}

.question {
  background-color: $questionColor;
  border: 1px solid $questionColor;
  border-radius: 2px;
}

.discussion {
  background-color: $solid-green;
  border: 1px solid $solid-green;
  border-radius: 2px;
}

.silence {
  background-color: $solid-white;
  border: 1px solid $shadow-blue-alpha;
  border-radius: 2px;
}

// No longer used.
// .no-data {
//   background: repeating-linear-gradient(
//     45deg,
//     $solid-light-grey,
//     $solid-light-grey 5px,
//     $solid-lighter-grey 5px,
//     $solid-lighter-grey 10px
//   );
//   border: 1px solid $solid-light-grey;
//   border-radius: 2px
// }

.legend-text {
    display: flex;
    @include text-maintitle;
    text-align: left;
    margin-right: 16px;
    align-items: flex-start;
}

.timeline {
    display: flex;
    min-height: 32px;
    width: 100%;
    margin-top: 16px;
    background-color: $solid-white;
    align-self: flex-start;
}

.utterance {
    position: absolute;
    height: 30px;
    border: 1px solid $solid-green;
    border-radius: 2px;
    background-color: $solid-green;
    &.question {
      background-color: $questionColor;
      border: 1px solid $questionColor;
    }
}

.time-textbox {
    display: flex;
    width: 100%;
    min-height: 20px;
    max-height: 20px;
    margin-top: 8px;
    justify-content: space-between;
}

.time-text {
  @include text-caption1;
  opacity: 0.5;
}

.no-data-span {
  position: absolute;
  height: 30px;
  border-radius: 2px;
  background: repeating-linear-gradient(
    45deg,
    $solid-light-grey,
    $solid-light-grey 5px,
    $solid-lighter-grey 5px,
    $solid-lighter-grey 10px
  );
}
