@import '../design-system';

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
}

.greeting {
    @include text-title1($text-blue);
    margin: $stack-XS;
    margin-top: $space-2XL;
}

.instruction {
    @include text-caption1($text-light);
    width: 343px;
}

.home-button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 311px;
    height: 64px;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 0 2px 0 $shadow-blue-alpha, 0 8px 8px -4px $shadow-blue-alpha;
    transition: box-shadow 0.2s ease-in-out;
    margin: $stack-L;
    background-color: $solid-white;
    padding:$inset-squish-S;
    &:hover {
        cursor: pointer;
        background-color: $solid-ultra-white;
        box-shadow: 0 0 2px 0 $shadow-blue-alpha, 0 12px 12px -3px $shadow-blue-alpha;
    }

    .button-text {
        margin-left: 8px;
        @include text-headline($text-blue);
    }

    .icon {
        width: 24px;
        height: 24px;
    }
}

.logo {
    width: 146px;
    height: 146px;
    display: flex;
    margin-top: 51px;
    margin-bottom: 50px;
    align-content: center;
}