@import '../design-system';

.empty-session-list {
    margin-top: 50px;
    text-align: center;
}

.click-mask {
    position: absolute;
    top:0px;
    left: 0px;
    height: 100%;
    width: 310px;
    cursor: pointer;
}

.dialog-window {
    min-width: 270px;
}

.basic-button {
  margin: 10px 0px !important;
}

.field-input {
    @include text-subhead($text-regular);
    width: 100%;
    border: 1px solid $shadow-blue-alpha;
    border-radius: $space-XS;
    box-sizing: border-box;
    padding: $inset-squish-M2;
    outline: none;

    &:focus {
      border-color: $solid-blue;
      box-shadow: 0 1px 2px 0 rgba(63,30,131,0.2);
    }

    &::placeholder {
      opacity: 0.3;
    }
}

.active-session {
    fill: $solid-red !important;
}

.add-folder-to-home {
  height: 28px;
  width: auto;
  padding: 10px 14px 10px 10px;
}

.folder-empty {
  text-align: center;
  font-size: 23px;
  color: gray;
  padding-top: 25px;
  font-style: italic;
}
