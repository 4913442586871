@import 'color';

// Global font mixins. To use do @include 'name'

// text
@mixin text-title1($color: $text-black) {
    font-family: 'SST', sans-serif;
    font-weight: 700;
    font-size: 38px;
    line-height: 56px;
    color: $color;
}

@mixin text-title2($color: $text-black) {
    font-family: 'SST', sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 44px;
    color: $color;
}

@mixin text-title3($color: $text-black) {
    font-family: 'SST', sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: $color;
}

@mixin text-subhead($color: $text-black) {
    font-family: 'SST', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color;
}

@mixin text-headline($color: $text-black) {
    font-family: 'SST', sans-serif;
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    color: $color;
}

@mixin text-body($color: $text-regular) {
    font-family: 'SST', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color;
}

@mixin text-caption1($color: $text-black) {
    font-family: 'SST', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $color;
}

@mixin text-maintitle($color: $text-regular) {
    font-family: 'SST', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $color;
}

// feature
@mixin feature-headline {
    font-family: 'SST', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $text-blue;
}

@mixin feature-link {
    font-family: 'SST', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $text-light-blue;
}

@mixin feature-body {
    font-family: 'SST', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $text-error;
}

@mixin feature-caption1 {
    font-family: 'SST', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $text-error;
}

@mixin no-text-select {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
