@import '../design-system';

.button-margin {
  margin: 20px 0px;
}

.instruction {
  width: 343px;
  margin: 6px 0px;
  @include text-caption1($text-light);
}
 
.text-input {
  font-size: 14px;
  padding: 0px 10px;
  margin: 12px 0px;
  width: 330px;
  height: 30px;
  box-shadow: none;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #a7a7a7;
  outline: none;
  transition: border-color 0.5s;
}

.text-input:focus {
  border-color: #006db3;
}

.dialog-window {
  min-width: 270px;
}

.list-container {
  display: flex;
  flex-flow: wrap;
  align-content: center;
  justify-content: space-evenly;
}