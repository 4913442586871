@import '../design-system';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.add-dialog {
  min-width: 270px;
}

.dialog-heading {
  text-align: center;
  align-items: center;
  @include text-headline;
}

.dialog-body {
  margin: 8px 32px;
  text-align: center;
  @include text-body;
}

.field-input {
  @include text-subhead($text-regular);
  width: 100%;
  border: 1px solid $shadow-blue-alpha;
  border-radius: $space-XS;
  box-sizing: border-box;
  padding: $inset-squish-M2;
  outline: none;

  &:focus {
    border-color: $solid-blue;
    box-shadow: 0 1px 2px 0 rgba(63,30,131,0.2);
  }

  &::placeholder {
    opacity: 0.3;
  }
}

.dropdown-input {
  width: 270px;
  height: 40px;
  font-size: 16px;
}

.input-header {
  margin-top: 10px;
}

.error-status {
  color: $text-error;
  white-space: pre-line;
}

.option-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 300px;
  height: 40px;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 $shadow-blue-alpha, 0 8px 8px -4px $shadow-blue-alpha;
  transition: box-shadow 0.2s ease-in-out;
  margin: $stack-L;
  background-color: $solid-white;
  padding:$inset-squish-S;
  &:hover {
      cursor: pointer;
      background-color: $solid-ultra-white;
      box-shadow: 0 0 2px 0 $shadow-blue-alpha, 0 12px 12px -3px $shadow-blue-alpha;
  }
  @include text-headline($text-blue);
}

.section-header {
  font-family: 'SST', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: $solid-light-grey;
  margin: 10px 0px;
}

.basic-button {
  margin: 10px 0px;
}

.user-table {
  max-height: 300px;
  overflow-y: auto;

  .user-row {
    grid-template-columns: 160px 70px 70px 55px;
    display: grid;

    .user-name {
      grid-column: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .user-role {
      grid-column: 2;
    }

    .user-lock {
      grid-column: 3;
    }

    .user-api {
      grid-column: 4;
    }

    .bold {
      font-weight: bold;
    }
  }
}
