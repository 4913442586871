@import '../design-system';

:host {
  width: 100%;
  z-index: 1;
}

.header-grid {
    background-color: #FCFDFF;
    grid-template-columns: min-content auto min-content;
    grid-template-rows: 56px;
    display: grid;
    width: 100%;
    box-shadow: 0 1px 1px 0 rgba(57,57,128,0.20);
    &.shadow {
        box-shadow: 0 1px 6px 0 #e0e0ed;
    }

    .left {
        grid-column: 1;
        padding: 16px;
        @include text-headline($text-blue);
        @include no-text-select;
        cursor: pointer;
    }

    .center {
        grid-column: 2;
        @include text-headline($text-blue);
        @include no-text-select;
        line-height: 56px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .right {
        grid-column: 3;
        @include text-headline($text-blue);
        @include no-text-select;
        line-height: 56px;
        min-width: 50px;
        padding: 0px 5px;
        text-align: center;
        cursor: pointer;
    }
}

.disabled {
    cursor: default !important;
    color: lightgrey !important;
}
