@import '../design-system';

.keyword-lists-header {
    @include text-body($text-blue);
    width: 100%;
    background-color: $solid-white;
    padding-top: $space-2XL;
    padding-bottom: $space-2XL;
    box-shadow: 0 1px 1px 0 rgba(57,57,128,0.20);

    div {
        position: absolute;
        &:hover {
            cursor: pointer;
        }
    }

    .cancel-div {
        left: $space-L;
    }

    .save-div {
        right: $space-L;
        font-weight: bold;
    }
}
.keyword-lists-title {
    padding: $inset-L;
    @include text-title3;
    color: navy;
    background: transparent;
    border: 1px solid gray;
    background-color: white;
    text-align: center;
    outline: none;
    width: 275px;
    height: 24px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;

    &::placeholder {
        color: lightgray;
        font-size: 24px;
        font-style: italic;
    }

    &:focus {
        border: 1px solid $solid-blue;
        box-shadow: 0 1px 2px 0 rgba(63,30,131,0.2);
    }
}

.empty-keyword-list {
    margin-top: $space-L;
    text-align: center;
}

.load-text {
    font-size: 16px;
    margin-bottom: 0px;
    width: 275px;
}

.change-keywords-container {
    position: relative;
    margin: 0px 0px 10px 0px;

    input {
        width: 85%;
        font-size: 16px;
        border: 1px solid $shadow-blue-alpha;
        border-radius: $space-XS;
        padding: $inset-squish-M2;
        outline: none;

        &:focus {
            border-color: $solid-blue;
            box-shadow: 0 1px 2px 0 rgba(63,30,131,0.2);
        }

        &::placeholder {
            opacity: 0.3;
        }
    }

    img {
        position: absolute;
        right: 20px;
        top: 15px;
        cursor: pointer;
    }

    .add-new-keywords-status {
        width: 240px;
        text-align: center;
        font-size: 12px;
        color: $text-error;
    }

    &.invalid-keyword {
        input {
            border-color: $solid-red;
        }

        .add-new-keywords-status span {
            color: $solid-red;
        }
    }
}

.keyword-lists-container {
    height: calc(100% - 140px);
    overflow-y: auto;
    margin-top: 8px;
    width: 260px;
}

.save-invalid {
    cursor: default !important;
    color: lightgrey;
}

.remove-button-container {
    position: relative;
    display: inline;
    right: 36px;
    top: 50%;
    transform: translate(0, -50%);
  }