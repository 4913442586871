.medium-button {
  width: 374px !important;
  margin: 20px 0px !important;
}

.basic-button {
  width: 100%;
  text-align: center;
  border-radius: 26px;
  border: none;
  padding: 12px 16px 12px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  -webkit-box-shadow: 0 0 8px 0 rgba(69, 69, 153, 0.2), 0 8px 8px -4px rgba(69, 69, 153, 0.2);
          box-shadow: 0 0 8px 0 rgba(69, 69, 153, 0.2), 0 8px 8px -4px rgba(69, 69, 153, 0.2);
  -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
  transition: -webkit-box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  font-family: 'SST', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FAFAFC;
  background: linear-gradient(147.59deg, #764BFF 0%, #7BB7F1 100%);
}

.basic-button:focus {
  outline: 0;
}

.basic-button:hover {
  -webkit-box-shadow: 0 0 2px 0 rgba(69, 69, 153, 0.2), 0 12px 12px -4px rgba(69, 69, 153, 0.2);
          box-shadow: 0 0 2px 0 rgba(69, 69, 153, 0.2), 0 12px 12px -4px rgba(69, 69, 153, 0.2);
}

.basic-button:active {
  -webkit-box-shadow: 0 0 2px 0 rgba(57, 57, 128, 0.08), 0 6px 6px -4px rgba(57, 57, 128, 0.24);
          box-shadow: 0 0 2px 0 rgba(57, 57, 128, 0.08), 0 6px 6px -4px rgba(57, 57, 128, 0.24);
}

.delete-button {
  width: 100%;
  text-align: center;
  border-radius: 26px;
  border: none;
  padding: 12px 16px 12px 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  -webkit-box-shadow: 0 0 8px 0 rgba(69, 69, 153, 0.2), 0 8px 8px -4px rgba(69, 69, 153, 0.2);
          box-shadow: 0 0 8px 0 rgba(69, 69, 153, 0.2), 0 8px 8px -4px rgba(69, 69, 153, 0.2);
  -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
  transition: -webkit-box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  font-family: 'SST', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FAFAFC;
  background: #FF6363;
}

.delete-button:focus {
  outline: 0;
}

.delete-button:hover {
  -webkit-box-shadow: 0 0 2px 0 rgba(69, 69, 153, 0.2), 0 12px 12px -4px rgba(69, 69, 153, 0.2);
          box-shadow: 0 0 2px 0 rgba(69, 69, 153, 0.2), 0 12px 12px -4px rgba(69, 69, 153, 0.2);
}

.delete-button:active {
  -webkit-box-shadow: 0 0 2px 0 rgba(57, 57, 128, 0.08), 0 6px 6px -4px rgba(57, 57, 128, 0.24);
          box-shadow: 0 0 2px 0 rgba(57, 57, 128, 0.08), 0 6px 6px -4px rgba(57, 57, 128, 0.24);
}

.cancel-button {
  width: 100%;
  border-radius: 26px;
  border: none;
  background: transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 12px 16px 12px 16px;
  cursor: pointer;
  -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
  transition: -webkit-box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  font-family: 'SST', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3991CC;
}

.cancel-button:focus {
  outline: 0;
}

.pod-button {
  border-radius: 18px;
  border: none;
  fill: #7878FA;
  background: transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 12px 16px 12px 16px;
  cursor: pointer;
  -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
  transition: -webkit-box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  font-family: 'SST', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #3991CC;
}

.pod-button:focus {
  outline: 0;
}

.pod-button .light-svg {
  margin-top: 5px;
}

.pod-overview-button {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: 'SST', sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #2B2B2E;
  width: 150px;
  height: 150px;
  background-color: #FCFDFF;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-left: 8px;
  margin-right: 8px;
  -webkit-box-shadow: 0 0 2px 0 rgba(69, 69, 153, 0.2), 0 8px 8px -4px rgba(69, 69, 153, 0.2);
          box-shadow: 0 0 2px 0 rgba(69, 69, 153, 0.2), 0 8px 8px -4px rgba(69, 69, 153, 0.2);
  -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
  transition: -webkit-box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
}

.pod-overview-button:hover {
  cursor: pointer;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 0 2px 0 rgba(69, 69, 153, 0.2), 0 12px 12px -3px rgba(69, 69, 153, 0.2);
          box-shadow: 0 0 2px 0 rgba(69, 69, 153, 0.2), 0 12px 12px -3px rgba(69, 69, 153, 0.2);
}

.pod-overview-button *::-moz-selection {
  background: none;
}

.pod-overview-button *::selection {
  background: none;
}

.pod-overview-button .pod-overview-icon {
  margin-top: 15px;
}

.svgpulse {
  -webkit-animation: svgpulse 1s ease-out infinite;
          animation: svgpulse 1s ease-out infinite;
}

@-webkit-keyframes svgpulse {
  0% {
    stroke-width: 0;
    r: 31.5;
  }
  15% {
    stroke-width: 0;
    r: 31.5;
  }
  100% {
    stroke-width: 8;
    r: 35.5;
  }
}

@keyframes svgpulse {
  0% {
    stroke-width: 0;
    r: 31.5;
  }
  15% {
    stroke-width: 0;
    r: 31.5;
  }
  100% {
    stroke-width: 8;
    r: 35.5;
  }
}

.dc-checkbox {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dc-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  right: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.dc-checkbox input:checked ~ .checkmark {
  background-color: #7878FA;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.dc-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.dc-checkbox .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  width: 100%;
  height: auto;
}

.footer-bar {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
  background-color: rgba(252, 252, 255, 0.6);
  -webkit-box-shadow: 0 -1px 4px 0 rgba(69, 69, 153, 0.2);
          box-shadow: 0 -1px 4px 0 rgba(69, 69, 153, 0.2);
}

.footer {
  width: 100%;
}

.load-text {
  font-family: 'SST', sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  color: #58585C;
  width: auto;
  margin-bottom: 32px;
}

.load-text.onload {
  opacity: 0;
}

.load-text.onload.loading {
  opacity: 1;
  -webkit-transition: opacity 0.7s;
  transition: opacity 0.7s;
}

.load-text-description {
  font-family: 'SST', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #58585C;
}

.list-container2 {
  margin-top: 8px;
  -ms-flex-line-pack: center;
      align-content: center;
}

.list-container {
  max-width: 375px;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: 8px;
}

.list-container .list {
  list-style-type: none;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

.list-container .list .pod-item {
  -ms-grid-columns: 48px auto min-content;
      grid-template-columns: 48px auto -webkit-min-content;
      grid-template-columns: 48px auto min-content;
  -ms-grid-rows: 56px;
      grid-template-rows: 56px;
  display: -ms-grid;
  display: grid;
  background: #FCFDFF;
  -webkit-box-shadow: 0 0 2px 2px rgba(69, 69, 153, 0.2);
          box-shadow: 0 0 2px 2px rgba(69, 69, 153, 0.2);
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: #FCFDFF;
  margin: 12px 6px;
}

.list-container .list .pod-item .pod-icon {
  -ms-grid-column: 1;
  grid-column: 1;
  padding: 20px;
}

.list-container .list .pod-item .pod-text {
  -ms-grid-column: 2;
  grid-column: 2;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  line-height: 56px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-container .list .pod-item .button-container {
  -ms-grid-column: 3;
  grid-column: 3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.list-container .list .folder-item {
  -ms-grid-columns: 50px auto min-content;
      grid-template-columns: 50px auto -webkit-min-content;
      grid-template-columns: 50px auto min-content;
      grid-template-areas: 'left middle right';
  display: -ms-grid;
  display: grid;
  position: relative;
  font-family: 'SST', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #58585C;
  background: #FCFDFF;
  -webkit-box-shadow: 0 0 2px 2px rgba(69, 69, 153, 0.2);
          box-shadow: 0 0 2px 2px rgba(69, 69, 153, 0.2);
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: #FCFDFF;
  margin: 12px 5px;
}

.list-container .list .folder-item .folder-icon {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  grid-area: left;
  padding: 14px 10px;
  height: 28px;
  width: auto;
}

.list-container .list .folder-item .folder-title {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 30px !important;
  padding-top: 12px;
  font-weight: 500;
}

.list-container .list .folder-item .open-folder-icon {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 3;
  grid-area: right;
  height: 24px;
  width: auto;
  padding-top: 16px;
  padding-right: 10px;
}

.list-container .list .session-item {
  -ms-grid-columns: 50px auto min-content;
      grid-template-columns: 50px auto -webkit-min-content;
      grid-template-columns: 50px auto min-content;
      grid-template-areas: 'left middle-top right' 'left middle-bottom right';
  display: -ms-grid;
  display: grid;
  position: relative;
  font-family: 'SST', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #58585C;
  background: #FCFDFF;
  -webkit-box-shadow: 0 0 2px 2px rgba(69, 69, 153, 0.2);
          box-shadow: 0 0 2px 2px rgba(69, 69, 153, 0.2);
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: #FCFDFF;
  margin: 12px 6px;
}

.list-container .list .session-item .session-icon {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 1;
  grid-area: left;
  fill: #7878FA;
  padding: 16px 10px;
}

.list-container .list .session-item .session-title {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: middle-top;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 30px !important;
  font-family: 'SST', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #58585C;
}

.list-container .list .session-item .session-date {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  grid-area: middle-bottom;
  font-family: 'SST', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #727278;
  padding-bottom: 2px;
}

.list-container .list .session-item .session-options {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 3;
  grid-area: right;
}

.breadcrumb-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
  margin-top: 5px;
}

.breadcrumb-container .crumb-name {
  color: #7878FA;
  text-overflow: ellipsis;
  max-width: 240px;
  white-space: nowrap;
  display: inline-block;
  height: 18px;
  cursor: pointer;
}

.breadcrumb-container .breadcrumb-arrow-icon {
  height: 24px;
  width: auto;
  cursor: pointer;
}

.dc-slider {
  -webkit-appearance: none;
  height: 8px;
  border-radius: 4px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
}

.dc-slider:hover {
  opacity: 1;
}

.dc-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #7878FA;
  cursor: pointer;
}

.dc-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #7878FA;
  cursor: pointer;
}

.discussion-graph-header {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 40px;
}

.page-container {
  overflow: auto;
  position: absolute;
  margin-top: 60px;
  height: calc(100% - 116px);
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto;
      grid-template-columns: auto;
  -ms-grid-rows: 40px auto;
      grid-template-rows: 40px auto;
      grid-template-areas: "header" "graph";
}

.header-container {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: header;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.header-container .header-spacer {
  width: 85px;
  height: 40px;
}

.header-container .header-text {
  line-height: 40px;
  text-align: center;
  overflow: hidden;
  min-width: 250px;
  max-width: 500px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  background-color: #6466E3;
  border-radius: 5px;
  margin-right: 10px;
  color: white;
  cursor: pointer;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.graph-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: graph;
  overflow-y: overlay;
  overflow-x: hidden;
  height: calc(100% - 20px);
}

.graph-container .timeline {
  position: relative;
  width: 70px;
  min-width: 70px;
  margin-left: 15px;
  font-size: 11px;
  line-height: 0px;
  color: #6466E3;
}

.graph-container .timeline .timestamp {
  position: relative;
  margin-top: 10px;
  margin-bottom: 100px;
}

.graph-container .transcript-column {
  position: relative;
  min-width: 250px;
  max-width: 500px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.graph-container .transcript-column .transcript {
  position: absolute;
  width: calc(100% - 15px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 5px;
  min-height: 40px;
  font-size: 16px;
  background-color: #ffffff;
  -webkit-box-shadow: 3px 3px 5px #b5b5b5;
          box-shadow: 3px 3px 5px #b5b5b5;
  border-radius: 5px;
  color: #414142;
}

.graph-container .transcript-column .transcript .transcript-text {
  margin: 5px 7px 5px 7px;
  line-height: 30px;
}

.graph-container .transcript-column .transcript .transcript-text .keyword-text {
  cursor: pointer;
}

.graph-container .transcript-column .transcript .transcript-text .speaker-tag {
  font-weight: bold;
}

.graph-container .transcript-column .transcript .transcript-text .question-highlight {
  background-color: yellow;
}

.graph-container .transcript-column .transcript .transcript-text .question-mark {
  font-size: 18px;
  height: 25px;
  min-width: 15px;
  color: white;
  font-style: bold;
  position: absolute;
  border-radius: 0px 5px 0px 5px;
  top: 0;
  right: 0;
  background-color: #7878FA;
  height: 29px;
  text-align: center;
  cursor: pointer;
}

.graph-container .transcript-column .transcript .transcript-text .question-mark:hover {
  background-color: yellow;
  color: black;
}

.graph-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 200px;
  width: 100%;
}

.graph-box .pie-chart {
  position: absolute;
  height: 200px;
}

.graph-box .pie-piece-text {
  position: absolute;
  height: 40px;
  width: 48px;
  background-color: #ffffff;
  text-align: center;
  line-height: 40px;
  border-radius: 40px;
  top: 80px;
}

.graph-legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: -10px;
  margin-bottom: 15px;
  width: 240px;
  -ms-flex-line-pack: center;
      align-content: center;
}

.graph-legend .color-box {
  height: 15px;
  width: 15px;
  margin-left: 5px;
  margin-top: 4px;
}

.graph-legend .name {
  padding-right: 10px;
  width: 80px;
  height: 20px;
  padding-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.question-container {
  max-height: 250px;
  min-height: 0px;
  position: relative;
  overflow: auto;
}

.question-container .question-item {
  background-color: #f7f8fc;
  -webkit-box-shadow: 5px 5px 10px #888888;
          box-shadow: 5px 5px 10px #888888;
  border-radius: 5px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #414142;
  font-size: 16px;
  margin: 10px 5px 10px 10px;
  width: 85%;
  padding: 10px;
}
