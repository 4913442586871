// Global spacing definitions

// Spacers
$space-3XS: 1px;
$space-2XS: 2px;
$space-XS: 4px;
$space-S: 8px;
$space-M: 12px;
$space-L: 16px;
$space-XL: 24px;
$space-2XL: 32px;
$space-3XL: 64px;
$space-4XL: 128px;

// Inset
$inset-XXS: 2px 2px 2px 2px;
$inset-XS: 4px 4px 4px 4px;
$inset-S: 8px 8px 8px 8px;
$inset-M: 12px 12px 12px 12px;
$inset-L: 16px 16px 16px 16px;
$inset-XL: 24px 24px 24px 24px;
$inset-2XL: 32px 32px 32px 32px;
$inset-3XL: 64px 64px 64px 64px;

// Inset Squish
$inset-squish-XS: 4px 8px 4px 8px;
$inset-squish-S: 8px 16px 8px 16px;
$inset-squish-M: 12px 24px 12px 24px;
$inset-squish-M2: 12px 16px 12px 16px;
$inset-squish-L: 16px 32px 16px 32px;

// Stack
$stack-XXS: 0 0 2px 0;
$stack-XS: 0 0 4px 0;
$stack-S: 0 0 8px 0;
$stack-M: 0 0 12px 0;
$stack-L: 0 0 16px 0;
$stack-XL: 0 0 24px 0;
$stack-XXL: 0 0 32px 0;
$stack-XXL2: 0 0 64px 0;

// Inline
$inline-XXS: 0 0 0 2px;
$inline-XS: 0 0 0 4px;
$inline-S: 0 0 0 8px;
$inline-M: 0 0 0 12px;
$inline-L: 0 0 0 16px;
$inline-XL: 0 0 0 24px;
$inline-XXL: 0 0 0 32px;
$inline-XXL2: 0 0 0 64px;
