@mixin noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.footer-bar {
  position: sticky;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
  background-color: rgba(99%, 99%, 100%, 0.6);
  box-shadow: 0 -1px 4px 0 $shadow-blue-alpha;
}

.footer {
  width: 100%;
}

.load-text {
  @include text-title3($text-regular);
  width: auto;
  margin-bottom: $space-2XL;

  &.onload {
    opacity: 0;

    &.loading {
      opacity: 1;
      transition: opacity 0.7s;
    }
  }
}

.load-text-description {
  @include text-body($text-regular);
}

.list-container2 {
  margin-top: $space-S;
  align-content: center;
}

.list-container {
  max-width: 375px;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: $space-S;
  //flex-grow: 1;

  .list {
    list-style-type: none;
    padding-inline-start: 0px;

    .pod-item {
      grid-template-columns: 48px auto min-content;
      grid-template-rows: 56px;
      display: grid;
      background: $solid-white;
      box-shadow: 0 0 2px 2px $shadow-blue-alpha;
      border-radius: 4px;
      border-style: solid;
      border-width: 2px;
      border-color: $solid-white;
      margin: 12px 6px;

      .pod-icon {
        grid-column: 1;
        padding: 20px;
      }

      .pod-text {
        grid-column: 2;
        user-select: none;
        line-height: 56px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .button-container {
        grid-column: 3;
        display: flex;
      }
    }

    .folder-item {
      grid-template-columns: 50px auto min-content;
      grid-template-areas:
        'left middle right';
      display: grid;
      position: relative;
      @include text-body($text-regular);
      background: $solid-white;
      box-shadow: 0 0 2px 2px $shadow-blue-alpha;
      border-radius: 4px;
      border-style: solid;
      border-width: 2px;
      border-color: $solid-white;
      margin: 12px 5px;

      .folder-icon {
        grid-area: left;
        padding: 14px 10px;
        height: 28px;
        width: auto;
      }

      .folder-title {
        grid-area: middle;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 30px !important;
        padding-top: 12px;
        font-weight: 500;
      }

      .open-folder-icon {
        grid-area: right;
        height: 24px;
        width: auto;
        padding-top: 16px;
        padding-right: 10px;
      }
    }

    .session-item {
      grid-template-columns: 50px auto min-content;
      grid-template-areas:
        'left middle-top right'
        'left middle-bottom right';
      display: grid;
      position: relative;
      @include text-body($text-regular);
      background: $solid-white;
      box-shadow: 0 0 2px 2px $shadow-blue-alpha;
      border-radius: 4px;
      border-style: solid;
      border-width: 2px;
      border-color: $solid-white;
      margin: 12px 6px;

      .session-icon {
        grid-area: left;
        fill: $solid-blue;
        padding: 16px 10px;
      }

      .session-title {
        grid-area: middle-top;
        user-select: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 30px !important;
        @include text-subhead($text-regular);
      }

      .session-date {
        grid-area: middle-bottom;
        @include text-caption1($text-light);
        padding-bottom: $space-2XS;
      }

      .session-options {
        grid-area: right;
      }
    }
  }
}

.breadcrumb-container {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-top: 5px;

  .crumb-name {
    color: #7878FA;
    text-overflow: ellipsis;
    max-width: 240px;
    white-space: nowrap;
    display: inline-block;
    height: 18px;
    cursor: pointer;
  }

  .breadcrumb-arrow-icon {
    height: 24px;
    width: auto;
    cursor: pointer;
  }
}