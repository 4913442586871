@import '../../design-system';

.selected {
  border-color: $solid-blue !important;
}

.add-to-home {
  height: 30px;
  width: auto;
  padding: 12px 0px 14px 12px;
}

.list {
  max-height: 300px;
  overflow-y: auto;
}

.list-container {
  height: min-content;
}
