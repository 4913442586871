@import 'color';

.dc-slider {
  -webkit-appearance: none;
  height: 8px;
  border-radius: 4px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.dc-slider:hover {
  opacity: 1;
}

.dc-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: $solid-blue;
  cursor: pointer;
}

.dc-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: $solid-blue;
  cursor: pointer;
}