@import '../design-system';

.empty-pod-list {
    margin-top: $space-L;
    text-align: center;
}

.pod-blinking {
  fill: $solid-red;
}

.text-input {
  @include text-subhead($text-regular);
  width: 100%;
  border: 1px solid $shadow-blue-alpha;
  border-radius: $space-XS;
  box-sizing: border-box;
  padding: $inset-squish-M2;
  outline: none;

  &:focus {
    border-color: $solid-blue;
    box-shadow: 0 1px 2px 0 rgba(63,30,131,0.2);
  }

  &::placeholder {
    opacity: 0.3;
  }
}

.input-status-text {
  @include text-subhead($text-error);
  min-height: 24px;
}

.dialog-window {
  min-width: 270px;
}

.selected-button {
  fill: $solid-red;
}

.pod-disconnected {
  fill: $solid-light-grey !important;
}

.pod-connected {
  fill: $solid-blue;
}