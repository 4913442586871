@import '../design-system';

.empty-keyword-list {
  margin-top: 50px;
  text-align: center;
}

.keyword-list-button {
  grid-template-columns: auto min-content;
  grid-template-areas:
  "left-top right"
  "left-bottom right";

  position: relative;
  display: grid;
  padding: 10px;
  border-style: solid;
  border-color: #FCFDFF;
  margin: $inset-L;
  background-color: #FCFDFF;
  border-radius: $space-XS;
  box-shadow: 0 0 2px 0 $shadow-blue-alpha, 0 8px 8px -4px $shadow-blue-alpha;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
      cursor: pointer;
      background-color: $solid-ultra-white;
      box-shadow: 0 0 2px 0 $shadow-blue-alpha, 0 12px 12px -3px $shadow-blue-alpha;
  }

  &:active {
      box-shadow: 0 0 2px 0 rgba(57,57,128,0.08), 0 6px 6px -4px rgba(57,57,128,0.24);
  }

  .keyword-list-header {
    grid-area: left-top;
    grid-template-columns: auto min-content;
    display: grid;
    .title {
      grid-column: 1;
      @include text-headline($text-regular);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .date {
      grid-column: 2;
      @include text-caption1($text-light);
      white-space:nowrap;
    }
  }

  .keyword-list-keywords {
    grid-area: left-bottom;
    @include text-caption1($text-light);
    margin-top: $space-S;
    width: 100%;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    &.show-all {
        margin-bottom: 38px;
        overflow: visible;
        height: auto;
    }
  }

  .keyword-list-options {
    grid-area: right;
  }

  .click-mask {
    position: absolute;
    top:0px;
    left: 0px;
    height: 100%;
    width: 275px;
    cursor: pointer;
  }
}

.keywords-selected {
  border-color: $solid-blue;
}

.dialog-window {
  min-width: 270px;
}
