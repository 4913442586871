@import '../design-system';

.text {
    position: absolute;
    font-family: "SST", sans-serif;
    left: $space-L;
    top: $space-S;
}

.time {
    @include text-title2;
}

.info {
    @include text-caption1($text-light);
}

.end-button {
    width: 80px;
    height: 48px;
    border-radius: 26px;
    background-color: #FF6363;
    margin: 16px 20px;
    box-shadow: 0 6px 8px -4px rgba(12%, 7%, 60%, 0.3);
    color: $text-white;
    font-family: "SST", sans-serif;
    font-size: 16px;
    outline: none;
    user-select: none;
    cursor: pointer;
}

.dialog-content {
    display: block;
}

.dialog-spinner {
    text-align: center;
}

.dialog-blur {
    filter: blur(2px);
}

.session-toolbar {
    grid-template-columns: 120px auto 120px;
    grid-template-areas:
    'left middle right';
    display: grid;

    .session-time {
        grid-area: left;
        padding: 8px;
    }

    .middle-content {
        grid-area: middle;
        display: flex;
        justify-content: center;
        ::ng-deep {
            .toolbar-button {
                width: 60px;
                cursor: pointer;

                .button-text {
                    font-size: 10px;
                    text-align: center;
                }

                .button-icon {
                    width: 40px;
                    height: 40px;
                    margin: 10px 0px 0px 10px;
                }
            }

            .toolbar-button:hover {
                background-color: $solid-lighter-grey;
            }
        }
    }

    .session-end {
        grid-area:right;
    }
}
